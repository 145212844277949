<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="3" xl="3">
        <common-card-docs
          :docs="infoCards.docs"
          :routesConfigDocsCard="routesConfigNoteCard"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3" xl="3">
        <common-card-notes
          :notas="infoCards.notas"
          :routesConfigNoteCard="routesConfigNoteCard"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="3" xl="3">
        <common-card-email
          :emails="infoCards.emails"
          :emailRoutesConfig="emailRoutesConfig"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="3" xl="3">
        <common-card-calls
            :llamadas="infoCards.llamadas"
            :routesConfigNoteCard="routesConfigNoteCard"
            :openAll="openAll"
            :canEdit="canEdit"
            @getData="getData"
            @showAll="showAll"
          />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCardDocs from "@/components/commonComponents/cards/CommonCardDocs.vue";
import CommonCardEmail from "@/components/commonComponents/cards/CommonCardEmail.vue";
import CommonCardNotes from "@/components/commonComponents/cards/CommonCardNotes.vue";
import CommonCardCalls from "@/components/commonComponents/cards/CommonCardCalls.vue";

export default {
  components: {
    CommonCardDocs,
    CommonCardEmail,
    CommonCardNotes,
    CommonCardCalls
  },
  props:{
    infoCards: Object,
    emailRoutesConfig: Object,
    clienteID: Number 
  },
  data() {
    return {
      routesConfigNoteCard: {
        route:'',
        action: 'POST'
      },
      switch_data: 1,
      openAll: false,
    }
  },
  methods: {
    getData(){
      this.$emit("getData")
    },
    showAll(val){
      this.openAll = val
    },
  },
  watch:{
    clienteID(){
      this.routesConfigNoteCard.route = '/v1/clientes/documento/upload/'+this.clienteID
    }
  }

};
</script>
|
